*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lobster', cursive;
  font-family: 'Merienda', cursive;
  /* font-family: 'Lato', sans-serif; */
/* font-family: 'Roboto Slab', serif; */
}

body{
  overflow-x: hidden;
}

h1{
  font-size: 2.5rem;
  font-weight: 700;
}

h2{
  font-size: 1.8rem;
  font-weight: 600;
}

h3{
  font-size: 1.4rem;
  font-weight: 800;
}

h4{
  font-size: 1.1rem;
  font-weight: 600;
}

h5{
  font-size: 1rem;
  font-weight: 400;
  color: #1d1d1d;
}

h6{
  color: #D8D8D8;
}

button{
  font-size: 0.8rem;
  font-weight: 700;
  outline: none;
  border: none;
  background-color: #1d1d1d;
  color: aliceblue;
  padding: 13px 30px;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s ease;
}

button:hover{
  background-color: #3a3833;
}

.hr{
  width: 50px;
  height: 2px;
  background-color: coral;
  opacity: 1;
}

.star {
  font-size: 1rem;
  color: goldenrod;
  padding: 10px 0;
}

.navbar{
  font-size: 16px;
  top: 0;
  left: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-nav .nav-link{
  padding: 0px 20px;
  color: black;
  transition: 0.3s ease;
}

.navbar .navbar-nav .nav-itemi{
  font-size: 1.2rem;
  padding: 0 7px;
  cursor: pointer;
  font-weight: 200;
  transition: 0.3s ease;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active,
.navbar .navbar-nav .nav-itemi:hover{
  color: coral;
}

#bar{
  font-size: 1.5rem;
  padding: 7px;
  cursor: pointer;
  font-weight: 500;
  transition: 0.3s ease;
  color: black;
}

#bar:hover, 
#bar.active{
  color: white;
}

@media only screen and (max-width: 991px){
  body > nav > div > button:hover,
  body > nav > div > button.focus{
      background-color: coral;
      border: none;
  }

  body > nav > div > button:hover #bar,
  body > nav > div > button.focus #bar{
      color: white;
      border: none;
  }

  #navbarSupportedContent > ul{
      margin: 1rem;
      justify-content: flex-end;
      align-items: flex-end;
      text-align: right;
  }

  #navbarSupportedContent > ul > li:nth-child(n) > a{
      padding: 10px 0;
  }
}

/* Mobile Nav */

.navbar-light .navbar-toggler {
  border: none;
  outline: none;
}

#home{
  background-image: url("assets/2.jpg");
  width: 100%;
  height: 85vh;
  background-size: cover;
  background-position: top 0px center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-items: center;
  margin-top: 3vh;
}

#home span{
  color: coral;
}

#new .one img{
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

#new .one .details{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.3s ease;

}

#new .one:hover .details{
  cursor: pointer;
  background-color: rgba(245, 178, 178, 0.7);
}

#new .one .details button{
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: #2a2a2a;
  background: none;
  text-transform: uppercase;
  border-bottom: 1px solid #2a2a2a;
  padding: 2.5px;
  transform: translateY(70px);
  transition: 0.3s ease;
}


#new .one .details button:hover{
  color: white;
  border-bottom: 1px solid white;
}


#new .one:nth-child(1) .details{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
#new .one:nth-child(2) .details{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
#new .one:nth-child(3) .details{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Products */

.product{
  cursor: pointer;
  margin-bottom: 2rem;
}

.product img{
  transition: 0.3s all;
}

.product:hover img{
  opacity: 0.9;
}

.product .buy-btn{
  background-color: coral;
  border-radius: 10px;
  transform: translateY(20px);
  opacity: 0;
  transition: 0.3s all;
}

.product:hover .buy-btn{
  transform: translateY(0);
  opacity: 1;
}

#banner{
  background-image: url("assets/banner/10.png");
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-position: top 70px center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#banner h4{
  color: #D8D8D8;
}

#banner h1{
  color: white;
}

#banner button{
  background-color: coral;
}

footer{
  background-color: #222222;
}

footer h5{
  color: #D8D8D8;
  font-weight: 700;
  font-size: 1.2rem;
}

footer li{
  padding-bottom: 4px;
}

footer li a{
  font-size: 0.8rem;
  color: #999;
}

footer li a:hover{
  font-size: 0.8rem;
  color: #D8D8D8;
}

footer p{
  color: #999;
  font-size: 0.8rem;
}

footer .copyright a{
  color: #000;
  width: 38px;
  height: 38px;
  background-color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  transition: 0.3s ease;
  margin: 0.5px;
}

footer .copyright a:hover{
  color: #fff;
  background-color: coral;
}

.product-details {
  margin-top: 100px !important;
}

.product-details-info {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  margin-top: 10px;
}

.product-details-info h6 {
  margin-bottom: 20px !important;
}

.discountPrice{
  text-decoration: line-through;
}

.singleImg{
  height: 400;
  width: 400;
  margin: 20;
}

.imghoverCont{
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 300px; */
  /* width: 300px; */
  overflow: hidden;
}

.imghover{
  transition: transform .2s;
}

.imghover:hover{
  transform: scale(1.1); 
}

.addressInput{
  width: 50%;
}

.imageSliderThum{
  width: 500px;
}


.tempContainer {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  /* padding: 10px; */
  margin: 5px;
}

.tempText {
  color: white;
  position: absolute;
  font-weight: 700;
}

.offerContainer{
  width: 350px;
  background: black;
}
.mensBack{
  background: url("./assets/back8.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.womensBack{
  background: url("./assets/back11.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.kidsBack{
  background: url("./assets/back9.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.CatFeature{
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 320px;
}

.orderCart{
  width: 700px;
}

/* About Page */

.page-header.about-header{
  background-image: url("assets/banner.png");
  width: 100%;
  height: 40vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 14px;
}

.about-head{
  display: flex;
  /* align-items: center; */
  padding: 40px 80px;
}

.about-head img{
  width: 50%;
  height: auto;
}

.about-head div{
  padding-left: 40px;
}

.about-app{
  text-align: center;
}

.about-app .video{
  width: 70%;
  height: 100%;
  margin: 30px auto 0 auto;
}

.about-app .video video{
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

@media (max-width: 499px) {
  .singleImg{
    height: 350;
    width: 350;
  }
  .product-details-info{
    padding: 0;
    margin-top: 20px;
  }
  .addressInput{
    width: 100%;
  }
  .imageSliderThum{
    width: 400px;
  }
  .orderCart{
    width: auto;
  }
  .orderTable{
    font-size: 5px;
  }
  .ConfirmTable{
    font-size: 7px;
  }
  .confirmText{
    font-size: 10px;
  }
  .confirmHead{
    font-size: 20px;
  }
  .offerContainer{
    width: inherit;
  }
  .about-head{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }

  .about-head div{
    padding:  10px;
  }

}