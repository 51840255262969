.contactUS{
    position: relative;
    width: 100%;
    padding: 40px 100px;
    background: linear-gradient(90deg, #222222 0%, #222222 30%, #03a9f5 30%, #03a9f5 100%);
    font-family: 'Roboto', sans-serif;
}
.contactUS .title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
}
.contactUS .title h2{
    color: #fff;
    font-weight: 500;
}

.box{
    position: relative;
}
.form{
    grid-area: form;
}
.info{
    grid-area: info;
}
.map{
    grid-area: map;
}
.contact {
    padding: 40px;
    background: #fff;
    box-shadow: 0 5px 15px #222222;
}
.box{
    position: relative;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-columns: 5fr 4fr;
    grid-template-areas: "form info" "form map";
    grid-gap: 20px;
    margin-top: 20px;
}
.contact h3{
    color: #222222;
    font-weight: 500;
    font-size: 1.4em;
    font-family: 'Roboto', sans-serif;
}
.formBox{
    position: relative;
    width: 100%;
}
.formBox .row50{
    display: flex;
    gap: 20px;
}
.inputBox{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 50%;
}
.formBox .row100 .inputBox{
    width: 100%;
}
.inputBox span{
    color: #18b7ff;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 500;
}
.inputBox input{
    padding: 10px;
    font-size: 1.1em;
    outline: none;
    border: 1px solid #222222;
}

.inputBox textarea{
    padding: 10px;
    font-size: 1.1em;
    outline: none;
    border: 1px solid #222222;
    resize: none;
    min-height: 220px;
    margin-bottom: 10px;
}
.inputBox input[type="submit"]{
    background: #ff578b;
    color: #fff;
    border: none;
    font-size: 1.1em;
    max-width: 120px;
    font-size: 500;
    cursor: pointer;
    padding: 14px 15px;
}
.inputBox ::placeholder{
    color: #999;
}

.info{
    background: #222222;
}
.info h3{
    color: #fff;
}
.info .infoBox div{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.info .infoBox div span{
    min-width: 40px;
    height: 40px;
    color: #fff;
    background: #18b7ff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    border-radius: 50%;
    margin-right: 15px;
}
.info .infoBox div p{
    color: #fff;
    font-size: 1.1em;
}
.info .infoBox div a{
    color: #fff;
    text-decoration: none;
    font-size: 1.1em;
}
.sci{
    max-width: 40px;
    display: flex;
}
.sci li{
    list-style: none;
    margin-right: 15px;
}
.sci li a{
    color: #fff;
    font-size: 2em;
    color: #ccc;
}
.sci li a:hover{
    color: #fff;
}

.map{
    padding: 0;
}
.map iframe{
    width: 100%;
    height: 100%;
}


/* respomsive */
@media (max-width: 991px){
    .contactUS{
        padding: 20px;
    }
    .box{
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas: "form" "info" "map";
    }
    .map{
        min-height: 300px;
    }
    .formBox .row50{
        display: flex;
        gap: 0;
        flex-direction: column;
    }
    .inputBox{
        width: 100%;
    }
    .contact{
        padding: 30px;
    }
}